import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import swal from "sweetalert";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const listPertanyaan = [
    {
        value: "Ya",
        label: "Ya",
    },
    {
        value: "Tidak",
        label: "Tidak",
    }
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    inputText: {
        width: "50%",
        marginLeft: theme.spacing(2)
    },
}));

function InputCr2(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [nmCustomer, setCustomer] = React.useState("");
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };
    const handleCustomer = (event, value) => {
        const customername = value != null ? value.label : "";
        setCustomer(customername);
    };

    const[bpkbName, setBpkb] = React.useState("");
    const[servicesName, setServices] = React.useState("");

    const handleBpkb = (event) => {
        console.log(event.target.value);
        setBpkb(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handleService = (event) => {
        setServices(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    // const handleQuestion3 = (event) => {
    //     setPertanyaantiga(event.target.value);
    //     setInputs(values => ({...values, [event.target.name]: event.target.value}));
    // }

    // const handleQuestion4 = (event) => {
    //     setPertanyaanempat(event.target.value);
    //     setInputs(values => ({...values, [event.target.name]: event.target.value}));
    // }

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const isLoading = false;

    const [open, setOpen] = React.useState(false);
    const [dtDo, setDtDo] = React.useState([]);
    const [nameSales, setNameSales] = React.useState();
    const [noSpk, setNoSpk] = React.useState();
    const [nameCustomer, setNameCustomer] = React.useState();
    const [nameIdp, setNameIdp] = React.useState();

    const handleClickOpen = (eventValue) => {
        setDtDo(eventValue);
        setNameSales(eventValue.nama_sales);
        setNameCustomer(eventValue.nama_cust);
        setNameIdp(eventValue.idh);
        setNoSpk(eventValue.no_spk);
        setInputs((values) => ({
            ...values,
            ["id_do"]: eventValue.id,
            ["tgl_do"]: eventValue.tgl_do,
        }));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setInputs([]);
    };

    // API List Data Prospek
    const [dataCustomer, setDataCustomer] = useState([]);
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [refreshDt, setRefresh] = useState();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataCustomer = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/area/sales/list/crdua/customer?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataCustomer(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getDataCustomer();
    }, [bulan, tahun]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/area/sales/list/crdua?bulan=${bulan}&tahun=${tahun}&customer=${nmCustomer}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun, nmCustomer, refreshDt]);


    const [listLeasing, setDataLeasing] = useState([]);
    const [optLeasing, setLeasing] = React.useState(year);

    const handleLeasing = (event) => {
        setLeasing(event.target.value);
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    // DATA LEASING
    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getLeasing = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/leasing/list/cabang`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataLeasing(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getLeasing();
    }, []);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        {
            field: "Proses DO",
            renderCell: (cellValues) => {
                return cellValues.row.crsatu == null ? (
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={(event) => {
                            handleClickOpen(cellValues);
                        }}
                    >
                        Proses
                    </Button>
                ) : (
                    <Stack spacing={1} alignItems="center">
                        <Stack direction="row" spacing={1}>
                            <Chip label="Sudah CR1" size="small" color="success" />
                        </Stack>
                    </Stack>
                );
            },
            minWidth: 150,
        },
        { field: "tgl", headerName: "Tanggal", minWidth: 150 },
        { field: "nama_cust", headerName: "Customer", minWidth: 200 },
        { field: "sumber", headerName: "Sumber Prospek", minWidth: 200 },
        { field: "alamat", headerName: "Alamat", minWidth: 200 },
        { field: "telp", headerName: "Telepon", minWidth: 200 },
        
    ];

    const handleClick = (values) => {
        console.log(values);
    };

    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios
            .post("https://api.aims.wijayatoyota.co.id/api/crdua/create", inputs)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                    setLoadButton(false);
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setLoadButton(false);
                    setOpen(false);
                    setRefresh(response);
                }
            });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography>
                <b>Input CR 2</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        style={{ float: "left" }}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        style={{ float: "left" }}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataCustomer}
                        onChange={handleCustomer}
                        renderInput={(params) => (
                            <TextField {...params} label="Customer" helperText="Pilih Customer" />
                        )}
                    />
                </div>
            </Box>
            {/* <TableContainer style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer> */}
            <br></br>
            <br></br>
            {nmCustomer != "" ? (
                <Box sx={{ flexGrow: 1 }}>
                    {dataProspek.map((datval) => [
                        <Card key={datval.id} sx={{  float:"left", width: "90%", margin: "0 auto", borderTop: "1px solid #e0e0e0", marginBottom: 2 }}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                >
                                    {datval.nama_cust}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: 10 }}
                                >
                                    {datval.tgl}<br></br>
                                    {datval.sumber}<br></br>
                                    {datval.alamat}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {datval.crdua == null ? (
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={(event) => {
                                            handleClickOpen(datval);
                                        }}
                                    >
                                        Proses CR 2
                                    </Button>
                                ) : (
                                    <Stack
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                        >
                                            <Chip
                                                label="Sudah Follow Up"
                                                size="small"
                                                color="success"
                                            />
                                        </Stack>
                                    </Stack>
                                )}
                            </CardActions>
                        </Card>
                        ,
                    ])}
                </Box>
            ) : (
                ""
            )}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle style={{backgroundColor:bgColors.Yellow, marginBottom:'10px', color:'#ffffff'}}>{"Input CR 2"}</DialogTitle>
                    <DialogContent>
                    <Chip label={'No.SPK: '+dtDo.no_spk} color="primary"  variant="outlined" />
                    <Chip label={'Nama Sales: '+dtDo.nama_sales} color="success"  variant="outlined" />
                    <Chip label={'Nama Customer: '+dtDo.nama_cust} color="success"  variant="outlined" />
                    <Chip label={'No. Rangka: '+dtDo.no_rangka} color="success"  variant="outlined" />
                        <br />
                        <br />
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    defaultValue={nameIdp}
                                    variant="outlined"
                                    name="idp"
                                    label="Idp"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    style={{ display: "none" }}
                                    helperText="IDP"
                                    sx={{ display: "none" }}
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="tanggal"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    type="date"
                                    helperText="Tanggal"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    select
                                    variant="outlined"
                                    name="service"
                                    label="Mengingatkan Service 1000 dan 5000 Km"
                                    value={servicesName}
                                    onChange={handleService}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Service"
                                >
                                    {listPertanyaan.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    select
                                    variant="outlined"
                                    name="bpkb"
                                    value={bpkbName}
                                    label="Menginformasikan Mengenai BPKB"
                                    onChange={handleBpkb}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="BPKB"
                                >
                                    {listPertanyaan.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="keluhan"
                                    label="Keluhan"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Keluhan"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="refsatu"
                                    label="Referensi 1"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Referensi 1"
                                />
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="telpsatu"
                                    label="Telepon Satu"
                                    type="number"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Telepon Satu"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="refdua"
                                    label="Referensi 2"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Referensi 2"
                                />
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="telpdua"
                                    label="Telepon Dua"
                                    type="number"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Telepon Dua"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="reftiga"
                                    label="Referensi 3"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Referensi 3"
                                />
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="number"
                                    name="telptiga"
                                    label="Telepon Tiga"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Telepon Tiga"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                        >
                            {loadButton === true ? (<CircularProgress size="1.2rem" color="inherit" />) : <Typography>Submit</Typography>}
                        </Button> */}
                        {loadButton === true ? 
                            <Button
                                size="large"
                                variant="contained"
                                color="secondary"
                                disabled
                            > 
                                <CircularProgress size="1.2rem" color="inherit" />
                            </Button>
                        :  
                            <Button
                                size="large"
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                            > 
                                <Typography>Submit</Typography>
                            </Button>
                        }
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default InputCr2;

if (document.getElementById("salesinputcrdua")) {
    ReactDOM.render(<InputCr2 />, document.getElementById("salesinputcrdua"));
}
