import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CSVLink } from "react-csv";

import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';

import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Autocomplete from "@mui/material/Autocomplete";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const person = JSON.parse(localStorage.getItem("person"));
const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};


const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    btndelete: {
        fontSize: theme.spacing(2),
    },
}));
function DoStock(props) {

    const tipestock = [
        {
            value: "Free",
            label: "Free",
        },
        {
            value: "onprogress",
            label: "On Progress",
        },
        {
            value: "outstanding",
            label: "Outstanding",
        },
        {
            value: "stockout",
            label: "Stock Out",
        },
    ];

    const kategoriStock = [
        {
            value: "onhand",
            label: "ON HAND",
        },
        {
            value: "bartercabang",
            label: "Barter Cabang",
        },
        {
            value: "barterdealer",
            label: "Barter Dealer",
        },
        // {
        //     value: "barterout",
        //     label: "Barter Out",
        // },
        // {
        //     value: "arunit",
        //     label: "AR Unit Penjualan",
        // },
    ];

    const statusStock = [
        {
            value: "Regular",
            label: "Regular",
        },
        {
            value: "Outstanding",
            label: "Outstanding",
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [settipe, setTipeStock] = React.useState([]);
    const [setkategori, setkategoriStock] = React.useState("");
    const [spvName, setSpv] = React.useState("");
    const [inputs, setInputs] = React.useState([]);
    const [dataStock, setDataStock] = useState([]);
    const [dataSpv, setDataSpv] = useState([]);
    const [nmStock, setStock] = React.useState("");
    const [typeDoStock, settypeDoStock] = React.useState("");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    useEffect(() => {
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/stock/list/option/onhand`;
            try {
                const response = await axios.get(url);
                console.log(response);
                setDataStock(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/stock/list/spv`;
            try {
                const response = await axios.get(url);
                console.log(response);
                setDataSpv(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);
    
    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleTipeStock = (event) => {
        setTipeStock(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };
    const handleKtgStock = (event) => {
        setkategoriStock(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };
    const handleChangeSpv = (event) => {
        setSpv(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleStock = (event, value) => {
        setStock(event.target.value);
        const stockname = value != null ? value.id : "";
        console.log(stockname);
        setInputs(values => ({...values, ["stock"]: stockname == "" ? event.target.value : stockname}));
    };
    const handletypeDoStock = (event) => {
        settypeDoStock(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    
    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios.post('https://api.aims.wijayatoyota.co.id/api/stock/deliveryorder/create', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                window.location.href = "/stock/overviewstock";
            }
        });
    };

    const dataProgress = [
        {
            value: "1",
            label: "KTP",
        },
        {
            value: "2",
            label: "SPK",
        },
        {
            value: "3",
            label: "AJU AFI",
        },
        {
            value: "4",
            label: "REGISTER DO",
        },
        {
            value: "5",
            label: "DP LUNAS",
        },
        {
            value: "6",
            label: "PO KONTRAK ASLI",
        },
        {
            value: "7",
            label: "DONE",
        },
        {
            value: "8",
            label: "CANCEL",
        },
    ];

    const steps = [
        {
            label: 'KTP',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File KTP"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_ktp"
            //                 type="file"
            //             >
            //             </TextField>,
            description: "",
        },
        {
            label: 'SPK',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File SPK"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_spk"
            //                 type="file"
            //              >
            //              </TextField>,
            description: "",
        },
        {
            label: 'AJU AFI',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File AJU AFI"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_aju_afi"
            //                 type="file"
            //              >
            //              </TextField>,
            description: "",
        },
        {
            label: 'REGISTER DO',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File REGISTER DO"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_register_do"
            //                 type="file"
            //             >
            //             </TextField>,
            description: "",
        },
        {
            label: 'DP Lunas',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File DP Lunas"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_dp_lunas"
            //                 type="file"
            //             >
            //             </TextField>,
            description: "",
        },
        {
            label: 'PO KONTRAK ASLI',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File PO Kontrak Asli"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_po_kontrak_asli"
            //                 type="file"
            //             >
            //             </TextField>,
            description: "",
        },
        {
            label: 'Status',
            description: `Done or Cancell`,
        },
    ];
    
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />

            <Typography><b>Delivery Order</b></Typography>

            {/* Kategori Onhand */}
            <br></br>
            <Box style={{}}>
                <form onSubmit={handleSubmit}>
                    <Card sx={{  float:"left", width: "100%", margin: "0 auto", borderTop: "1px solid #e0e0e0", marginBottom: 2 }}>
                        <CardContent>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    select
                                    value={typeDoStock}
                                    onChange={handletypeDoStock}
                                    label="Type Stock"
                                    helperText="Pilih Type"
                                    name="statusstock"
                                    variant="outlined"
                                    sx={{width: "100%"}}
                                    size="small"
                                    required
                                >
                                    {statusStock.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                {/* <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    defaultValue=""
                                    label="Pilih Stock"
                                    helperText="Pilih Stock"
                                    onChange={handleStock}
                                    sx={{width: "100%"}}
                                    size= "small"
                                    name="id_stock"
                                    required
                                >
                                    {dataStock.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.model} - {option.warna} - {option.type} - {option.no_rangka}
                                        </MenuItem>
                                    ))}
                                </TextField> */}
                                {typeDoStock === "Regular" ? 
                                    
                                    <Autocomplete
                                        disableClearable
                                        freeSolo
                                        id="combo-box-demo"
                                        options={dataStock}
                                        onChange={handleStock}
                                        name="id_stock"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Pilih Stock"
                                                name="id_stock"
                                                value={nmStock}
                                                onChange={handleStock}
                                                helperText="Pilih Stock"
                                                size="small"
                                                id="outlined-basic"
                                                variant="outlined"
                                            />
                                        )}
                                    />

                                :  
                                    ""
                                }
                                {typeDoStock === "Outstanding" ? 
                                    
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="Model Stock"
                                        helperText="Model Stock"
                                        onChange={handleChangeInput}
                                        size= "small"
                                        style={{ width: "100%" }}
                                        name="modelstock"
                                    ></TextField>

                                :  
                                    ""
                                }
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    defaultValue=""
                                    label="Pilih SPV"
                                    helperText="Pilih SPV (optional)"
                                    onChange={handleChangeSpv}
                                    sx={{width: "50%"}}
                                    size= "small"
                                    name="spv"
                                >
                                    {dataSpv.map((option) => (
                                        <MenuItem
                                            key={option.nama_spv}
                                            value={option.nama_spv}
                                        >
                                            {option.nama_spv}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-select-currency-native"
                                    defaultValue=""
                                    label="No DO"
                                    helperText="No DO"
                                    onChange={handleChangeInput}
                                    sx={{width: "50%"}}
                                    size= "small"
                                    name="no_do"
                                >
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >   
                                <TextField
                                    id="outlined-select-currency-native"
                                    defaultValue=""
                                    helperText="Tanggal DO"
                                    onChange={handleChangeInput}
                                    sx={{width: "50%"}}
                                    size= "small"
                                    name="tgl_do"
                                    type="date"
                                >
                                </TextField>
                                <TextField
                                    id="outlined-select-currency-native"
                                    defaultValue=""
                                    label="Customer"
                                    helperText="Customer"
                                    onChange={handleChangeInput}
                                    sx={{width: "50%"}}
                                    size= "small"
                                    name="customer"
                                    required
                                >
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >   
                                <TextField
                                    id="outlined-select-currency-native"
                                    defaultValue=""
                                    label="Sales"
                                    helperText="Sales"
                                    onChange={handleChangeInput}
                                    sx={{width: "50%"}}
                                    size= "small"
                                    name="sales"
                                ></TextField>
                                <TextField
                                    id="outlined-select-currency-native"
                                    defaultValue=""
                                    label="keterangan"
                                    helperText="keterangan"
                                    onChange={handleChangeInput}
                                    sx={{width: "50%"}}
                                    size= "small"
                                    name="keterangan"
                                >
                                </TextField>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                {/* <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index) => (
                                        <Step key={step.label}>
                                            <StepLabel
                                                optional={
                                                    index === 6 ? (
                                                        <Typography variant="caption">Last step</Typography>
                                                    ) : null
                                                }
                                            >
                                                {step.label}
                                            </StepLabel>
                                            <StepContent>
                                                <Typography>{step.description}</Typography>
                                                <Box sx={{ mb: 2 }}>
                                                    <div>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleNext}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                    </Button>
                                                    <Button
                                                        disabled={index === 0}
                                                        onClick={handleBack}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>
                                                    </div>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                                {activeStep === steps.length && (
                                    <Paper square elevation={0} sx={{ p: 3 }}>
                                    <Typography>All steps completed - you&apos;re finished</Typography>
                                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                        Reset
                                    </Button>
                                    </Paper>
                                )} */}
                                <TextField
                                    id="outlined-select-currency-native"
                                    defaultValue=""
                                    label="Progress"
                                    helperText="Progress"
                                    onChange={handleChangeInput}
                                    sx={{width: "100%"}}
                                    size= "small"
                                    name="progresstatus"
                                    select
                                >
                                    {dataProgress.map((option) => (
                                        <MenuItem
                                            key={option.label}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </CardContent>
                        <CardActions>
                            {loadButton === true ? 
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            }
                        </CardActions>
                    </Card>
                </form>
            </Box>
        </Box>
            
        
    );
}

export default DoStock;