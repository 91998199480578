import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import swal from "sweetalert";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from '@mui/material/InputAdornment';

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';
import { formLabelClasses } from "@mui/material";
import Alert from '@mui/material/Alert';

const drawerWidth = 240;

const person = JSON.parse(localStorage.getItem("person"));
const token = localStorage.getItem("access_token");

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    inputText: {
        width: "50%",
    },
}));

function InputDataProspek(props) {
    // const { window } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [kategoriList, setKategoriState] = React.useState("");
    const [optKategori, setKtg] = React.useState([]);
    const [kategorisumberList, setKategoriSumberState] = React.useState("");
    const [optSumber, setKtgsum] = React.useState([]);
    const [pameranList, setPameranState] = React.useState("");
    const [pameranOpt, setPameran] = React.useState([]);
    const [kecamatanList, setKecamatanState] = React.useState("");
    const [salesList, setSalesState] = React.useState("");
    const [kecamatanOpt, setKecamatan] = React.useState([]);
    const [salesOption, setSales] = React.useState([]);
    const [kelurahanList, setKelurahanState] = React.useState("");
    const [kelurahanOpt, setKelurahan] = React.useState([]);
    const [kategoriProspekList, setKategoriProspekState] = React.useState("");
    const [kategoriProspekOpt, setKategoriProspek] = React.useState([]);
    const [planningList, setPlanningState] = React.useState("");
    const [planningOpt, setPlanning] = React.useState([]);
    const [testDrive, setTestDriveState] = React.useState("");
    const [demand, setDemand] = React.useState("");
    const [testDriveOpt, setTestDrive] = React.useState([]);
    const [nmCustomer, setCustomer] = React.useState("");

    // API OPTION INPUT
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const allOption = useEffect(() => {
        axios
            .get("https://api.aims.wijayatoyota.co.id/api/area/sales/select/option")
            .then((response) => {
                setKtg(response.data.kategori);
                setKtgsum(response.data.sumber);
                setPameran(response.data.pameran);
                setKecamatan(response.data.kecamatan);
            });
    }, []);

    const optSales = useEffect(() => {
        axios
            .get("https://api.aims.wijayatoyota.co.id/api/area/sa/prospek/listsales")
            .then((response) => {
                setSales(response.data.data);
            });
    }, []);

    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios.post('https://api.aims.wijayatoyota.co.id/api/area/sa/prospek/create', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                })    
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                })
                setLoadButton(false);
                window.location.reload();
            }

        });
    };

    const [jeniscustomer, setJeniscustomer] = React.useState("");

    const handleJcust = (event) => {
        setJeniscustomer(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleKtg = (event) => {
        setKategoriState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleKtgsum = (event) => {
        setKategoriSumberState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handlePameran = (event) => {
        setPameranState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleKecamatan = (event) => {
        setKecamatanState(event.target.value);
        getKelurahan(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleClickSales = (event) => {
        setSalesState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleKelurahan = (event) => {
        setKelurahanState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleKtgProspek = (event) => {
        setKategoriProspekState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handlePlanning = (event) => {
        setPlanningState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handleTestDrive = (event) => {
        setTestDriveState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleDemand = (event) => {
        setDemand(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleCustomer = (event, value) => {
        setCustomer(event.target.value);
        const customername = value != null ? value.label : "";
        console.log(customername);
        setInputs(values => ({...values, ["customer"]: customername == "" ? event.target.value : customername}));
    };

    function getKelurahan(kecamatan) {
        axios
            .get("https://api.aims.wijayatoyota.co.id/api/kelurahan/find/kecamatan?nama_kecamatan="+kecamatan)
            .then((response) => {
                setKelurahan(response.data.data);
            });
    }

    function saveHarian(dtharian) {
        axios
          .post(`https://api.aims.wijayatoyota.co.id/api/harian/create`, inputs)
          .then((response) => {
            console.log(response);
            swal("Success", 'Data Berhasil disimpan!', "success", {
                buttons: false,
                timer: 2000,
              })
          });
    }

    const listJeniscustomer = [
        {
            value: "Suspect",
            label: "Suspect",
        },
        {
            value: "Prospek",
            label: "Prospek",
        },
    ];

    const listKategoriProspek = [
        {
            value: "BELUM PROSPEK",
            label: "BELUM PROSPEK",
        },
        {
            value: "LOW",
            label: "LOW",
        },
        {
            value: "MEDIUM",
            label: "MEDIUM",
        },
        {
            value: "HOT",
            label: "HOT",
        },
    ];

    const listPlanning = [
        {
            value: "Dalam 1 Bulan",
            label: "Dalam 1 Bulan",
        },
        {
            value: "1-3 Bulan",
            label: "1-3 Bulan",
        },
        {
            value: "Diatas 3 Bulan",
            label: "Diatas 3 Bulan",
        }
    ];

    const listTestDrive = [
        {
            value: "Ya",
            label: "Ya",
        },
        {
            value: "Tidak",
            label: "Tidak",
        }
    ];

    const listDemand = [
        {
            value: "First Buyer",
            label: "First Buyer",
        },
        {
            value: "Additional",
            label: "Additional",
        },
        {
            value: "Trade In",
            label: "Trade In",
        }
    ];

    const listKategori = [];

    const [kategori, setKategori] = React.useState();

    const handleKategori = (event) => {
        setKategori(event.target.value);
    };

    const [dataCustomer, setDataCustomer] = useState([]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataCustomer = async () => {
          const url = `https://api.aims.wijayatoyota.co.id/api/area/sales/inputharian/listcustomer`;
          try {
            const response = await axios.get(url);
            // if (response.statusText === "") {
              setDataCustomer(response.data.data);
            // } else {
              // throw new Error("Request failed");
            // }
          } catch (error) {
            console.log(error);
          }
        };
        getDataCustomer();
      }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Alert onClose={() => {}}>Halo, {person} Ini Halaman Input Data Customer Pasif</Alert>
            <br></br>

            <Box style={{ width: "100%" }}>
                <Card sx={{ minWidth: 300 }}>
                    <form onSubmit={handleSubmit}>
                        <CardContent>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <Autocomplete
                                    disableClearable
                                    freeSolo
                                    id="combo-box-demo"
                                    options={dataCustomer}
                                    onChange={handleCustomer}
                                    name="customer"
                                    style={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Customer"
                                            name="customer"
                                            value={nmCustomer}
                                            onChange={handleCustomer}
                                            helperText="Pilih Customer"
                                            size="small"
                                            id="outlined-basic"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                 <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    defaultValue=""
                                    label="Kecamatan"
                                    helperText="Pilih Kecamatan"
                                    name="kecamatan"
                                    onChange={handleKecamatan}
                                    className={classes.inputText}
                                    size= "small"
                                    required
                                >
                                    {kecamatanOpt.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.nama_kecamatan}
                                        >
                                            {option.nama_kecamatan}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    defaultValue=""
                                    label="Pilih Kelurahan"
                                    name="kelurahan"
                                    onChange={handleKelurahan}
                                    className={classes.inputText}
                                    size= "small"
                                    required
                                >
                                    {kelurahanOpt.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.kelurahan}
                                        >
                                            {option.kelurahan}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    label="No HP (62xxxxx)"
                                    helperText="No Handphone"
                                    name="nohp"
                                    onChange={handleChangeInput}
                                    type="number"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">62</InputAdornment>,
                                      }}
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    label="Alamat"
                                    helperText="Alamat Customer"
                                    name="alamat"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    label="Kendaraan Dimiliki"
                                    helperText="Kendaraan Dimiliki"
                                    name="kendaraanmiliki"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    label="KM"
                                    helperText="KM"
                                    name="km"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    label="Kendaraan Diminati"
                                    helperText="Kendaraan Diminati"
                                    name="kendaraanminat"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                />
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    defaultValue=""
                                    label="Sales"
                                    helperText="Pilih Sales"
                                    name="sales"
                                    onChange={handleClickSales}
                                    className={classes.inputText}
                                    size= "small"
                                    required
                                >
                                    {salesOption.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.sales}
                                        >
                                            {"Tim " + option.spv} {"-"} {option.sales}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="keterangan"
                                    label="Keterangan"
                                    helperText="Keterangan Lain"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    size="small"
                                    onChange={handleChangeInput}
                                />
                            </Box>
                        </CardContent>
                        <CardActions>
                            {loadButton === true ? 
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                        </CardActions>
                    </form>
                </Card>
            </Box>
        </Box>
    );
}

export default InputDataProspek;