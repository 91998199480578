import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import swal from 'sweetalert';
import axios from "axios";
import Alert from '@mui/material/Alert';
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const listKategori = [
    {
        value: "LOW",
        label: "LOW",
    },
    {
        value: "MEDIUM",
        label: "MEDIUM",
    },
    {
        value: "HOT",
        label: "HOT",
    }
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: 'right',
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green
    },
    editCell:{
        color:bgColors.Blue,
        backgroundColor: bgColors.Blue
    }

}));

function OtherEditKategori(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [kategori, setKategori] = React.useState();
    const [listSales, setSalesState] = React.useState();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const isLoading = false;

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [optSales, setSales] = useState([]);
    const [allProspek, setAllProspek] = useState([]);

    const handleChangeSales = (event) => {
        setSalesState(event.target.value);
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/listsales`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    // response.data.data instanceof Object;
                    setSales(response.data.data);
                    
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/other/editkategori?sales=${listSales}&bulan=${bulan}&tahun=${tahun}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun, listSales, kategori]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        { field: "tgl", headerName: "Tanggal", minWidth: 100 },
        { field: "nama_spv", headerName: "SPV", minWidth: 100 },
        { field: "nama_sales", headerName: "Sales", minWidth: 150 },
        { field: "nama_cust", headerName: "Nama Customer", minWidth: 150 },
        { field: "sumber", headerName: "Sumber", minWidth: 150 },
        { field: "kategori", headerName: "Kategori", minWidth: 150},
    ];

    //handle update data
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedId, setSelectedId] = React.useState([]);
    const handleSelect = (ids) =>{
            const selectedIDs = new Set(ids);
            const selectedRows = dataProspek.filter((row) =>
              selectedIDs.has(row.id),
            );
            setSelectedRows(Object.values(selectedRows).map((item) =>(item.kategori)));
            setSelectedId(Object.values(selectedRows).map((item) =>(item.id)));
            console.log(Object.values(selectedRows).map((item) =>(item.kategori)));
            console.log(Object.values(selectedRows).map((item) =>(item.id)));
    }

    const handleKategori = (event) => {
        setKategori(event.target.value);
        updatePost(event.target.value);
    };

    function updatePost(kategori) {
        axios
          .put(`https://api.aims.wijayatoyota.co.id/api/sales/kategori/update`, {
            dtid : selectedId,
            kategori : kategori
          })
          .then((response) => {
            swal("Success", 'Data Berhasil diupdate!', "success", {
                buttons: false,
                timer: 2000,
              })
          });
      }

    

    //end update data

    return (
        
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography>
                <b>Other : Edit Kategori</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        defaultValue=''
                        label="Pilih Sales"
                        onChange={handleChangeSales}
                        helperText="Pilih Sales"
                    >
                        {optSales.map((option) => (
                            <MenuItem key={option.id} value={option.sales}>
                                {option.sales}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="standard-basic"
                        select
                        value={bulan}
                        onChange={handleChange}
                        variant="standard"
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="standard-basic"
                        select
                        value={tahun}
                        onChange={handleChange2}
                        variant="standard"
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    
                </div>
                
            <div>

            </div>
            </Box>
            <Box style={{  }}>
                

                <TableContainer style={{ float:"left", height: 500, width: "100%" }}>
                    <DataGrid
                        rows={dataProspek}
                        rowCount={rowCountState}
                        loading={isLoading}
                        components={{ Toolbar: GridToolbar }}
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        page={page}
                        pageSize={pageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => setPage(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={columns}
                        checkboxSelection={true}
                        onSelectionModelChange={handleSelect}
                    />
                </TableContainer>
                <Alert severity="info" style={{marginBottom:'10px'}}>Untuk mengganti kategori, pilih checkbox di sebelah kiri baris data, lalu ubah kategori di bawah info ini.</Alert>
                <TextField style={{float:"left",marginLeft:'10px',width:'200px',display: selectedRows<1 ? 'none' : '' }}
                        id="outlined-select-currency-native"
                        select
                        defaultValue=''
                        label="LOW/MEDIUM/HOT"
                        onChange={handleKategori}
                        helperText="Ganti Kategori yang dipilih"
                    >
                        {listKategori.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                </TextField>
            </Box>
        </Box>
    );
}

export default OtherEditKategori;

if (document.getElementById("othereditkategori")) {
    ReactDOM.render(
        <OtherEditKategori />,
        document.getElementById("othereditkategori")
    );
}
