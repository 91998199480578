import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { CSVLink } from "react-csv";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

function Kecamatan(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month + 1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const [listSales, setSalesState] = React.useState();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    const [loadingSet, setLoadingData] = useState(false);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    // API List Data Prospek
    const [dataOverview, setDataOverview] = useState([]);
    const [dataOverview2, setDataOverview2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [optSales, setSales] = useState([]);

    const handleChangeSales = (event) => {
        setSalesState(event.target.value);
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            setLoadingData(true);
            const url = `https://api.aims.wijayatoyota.co.id/api/area/mra/customerpasif/kecamatan?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataOverview(response.data.data);
                    setLoading(false);
                    setLoadingData(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [bulan, tahun]);

    const columns = [
        { field: "kecamatan", headerName: "Kecamatan", minWidth: 200 },
        { field: "contact", headerName: "Contact", minWidth: 150, align: "center" },
        { field: "fu", headerName: "Follow Up", minWidth: 150, align: "center" },
        { field: "terhubung", headerName: "Terhubung", minWidth: 150, align: "center" },
        { field: "service", headerName: "Services", minWidth: 150, align: "center" },
        { field: "persentase", headerName: "Persen", minWidth: 150, align: "center" },
    ];

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography>
                <b>Kecamatan</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </div>
            </Box>
            <br></br>
            <Box style={{ width: "100%" }}>
                {loadingSet === true ? 
                    // <center><CircularProgress size="1.2rem" color="inherit" /></center>
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress />
                    </Stack>
                :  
                    ""
                }
            </Box>
            <TableContainer style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={dataOverview}
                    // rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rowsPerPageOptions={[25, 50, 100]}
                    // pagination
                    // page={page}
                    // pageSize={pageSize}
                    // paginationMode="server"
                    // onPageChange={(newPage) => setPage(newPage)}
                    // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
        </Box>
    );
}

export default Kecamatan;
