import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Container } from "@mui/material";
import Alert from '@mui/material/Alert';
import TableContainer from "@mui/material/TableContainer";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const person = JSON.parse(localStorage.getItem("person"));
const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    btndelete: {
        fontSize: theme.spacing(2),
    },
}));
function InputDataPasif(props) {

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    const [inputs, setInputs] = useState([]);
    const [kecamatanList, setKecamatanState] = React.useState("");
    const [kecamatanOpt, setKecamatan] = React.useState([]);

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    // GET KECAMATAN
    const allOption = useEffect(() => {
        axios
            .get("https://api.aims.wijayatoyota.co.id/api/area/sales/select/option")
            .then((response) => {
                setKecamatan(response.data.kecamatan);
            });
    }, []);

    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleKecamatan = (event) => {
        setKecamatanState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://api.aims.wijayatoyota.co.id/api/area/sa/custpasif/input', inputs).then(function(response){
            console.log(response);
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                })    
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                })
                window.location.reload();
            }
        });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            
            <Alert onClose={() => {}}>Halo, {person} Ini Halaman Input Data Customer Pasif</Alert>

            <Box style={{ width: "100%", marginTop: 10 }}>
                <Card sx={{ minWidth: 300 }}>
                    <form onSubmit={handleSubmit}>
                        <CardContent>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="nama_cust"
                                    label="Customer"
                                    helperText="Customer"
                                    variant="outlined"
                                    sx={{ width: "50%" }}
                                    onChange={handleChangeInput}
                                    size="small"
                                    required
                                />
                                <TextField
                                    id="outlined-basic"
                                    select
                                    onChange={handleKecamatan}
                                    name="kecamatan"
                                    label="Kecamatan"
                                    helperText="Pilih Kecamatan"
                                    variant="outlined"
                                    sx={{ width: "50%" }}
                                    size="small"
                                    required
                                >
                                    {kecamatanOpt.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.nama_kecamatan}
                                        >
                                            {option.nama_kecamatan}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="telp"
                                    label="No Telepon"
                                    helperText="No Telepon"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    sx={{ width: "50%" }}
                                    size="small"
                                    type="number"
                                    required
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="no_pol"
                                    label="No Polisi"
                                    onChange={handleChangeInput}
                                    helperText="No Polisi"
                                    variant="outlined"
                                    sx={{ width: "50%" }}
                                    size="small"
                                    required
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="tipe"
                                    label="Tipe Kendaraan"
                                    helperText="Tipe Kendaraan"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    sx={{ width: "50%" }}
                                    size="small"
                                    required
                                />
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </CardActions>
                    </form>
                </Card>
            </Box>
             
        </Box>
        
    );
}

export default InputDataPasif;