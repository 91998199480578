import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

function PameranList(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const [listPameran, setPameranState] = React.useState("all");
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);

    const [startdate, setStart] = React.useState(null);
    const [enddate, setEnd] = React.useState(null);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    // API List Data Prospek
    const [dataOverview, setDataOverview] = useState([]);
    const [dataOverview2, setDataOverview2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [optPameran, setPameran] = useState([]);

    const handleChangePameran = (event) => {
        setPameranState(event.target.value);
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/pameran`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    // response.data.data instanceof Object;
                    setPameran(response.data.data);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const startpameran = formatDate(startdate);
    const endpameran = formatDate(enddate);
    console.log(startpameran);
    console.log(endpameran);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/pameran/sub/listpameran?nama_pameran=${listPameran}&startdate=${startpameran}&enddate=${endpameran}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    setDataOverview(response.data.data);
                    setDataOverview2(response.data);
                    setLoading(false);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, startpameran, endpameran, listPameran]);

    const [rowCountState, setRowCountState] = React.useState(
        dataOverview2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataOverview2?.totalAll !== undefined
                ? dataOverview2?.totalAll
                : prevRowCountState
        );
    }, [dataOverview2?.totalAll, setRowCountState]);

    const columns = [
        { field: "jenis_cust", headerName: "Jenis", minWidth: 220 },
        { field: "tgl", headerName: "Tanggal" },
        { field: "lokasi_pameran", headerName: "Lokasi", minWidth: 200 },
        { field: "nama_spv", headerName: "SPV" },
        { field: "nama_sales", headerName: "Sales", minWidth: 150 },
        { field: "nama_cust", headerName: "Customer" },
        { field: "alamat", headerName: "Alamat" },
        { field: "kelurahan", headerName: "Kelurahan" },
        { field: "telp", headerName: "Telp", minWidth: 150 },
        { field: "kendaraan_minat", headerName: "Minat" },
        { field: "sumber", headerName: "Sumber Prospek", minWidth: 200 },
        { field: "kategori", headerName: "Kategori" },
        { field: "spk", headerName: "SPK" },
    ];

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/pameran/export/listpameran?nama_pameran=${listPameran}&startdate=${startpameran}&enddate=${endpameran}`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    setAllProspek(response.data.data);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getAllData();
    }, [startpameran, endpameran, listPameran]);

    const header = [
        "Jenis",
        "Tanggal",
        "Lokasi",
        "SPV",
        "Sales",
        "Customer",
        "Alamat",
        "Kelurahan",
        "Telp",
        "Minat",
        "Sumber Prospek",
        "Kategori",
        "SPK",
    ];

    const body3 = allProspek.map((data) => [
        data.jenis_cust,
        data.tgl,
        data.lokasi_pameran,
        data.nama_spv,
        data.nama_sales,
        data.nama_cust,
        data.alamat,
        data.kelurahan,
        data.telp,
        data.kendaraan_minat,
        data.sumber,
        data.kategori,
        data.spk,
    ]);

    const mynamefile =
        listPameran + " Data Pameran Periode " + startpameran + "-" + endpameran + ".csv";

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            style={{width: "100px"}}
                            label="Start Date"
                            value={startdate}
                            onChange={(newValue) => {
                                setStart(newValue.format());
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="End Date"
                            value={enddate}
                            onChange={(newValue) => {
                                setEnd(newValue.format());
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        defaultValue=""
                        label="Pilih Pameran"
                        onChange={handleChangePameran}
                        helperText="Pilih Pameran"
                    >
                        {optPameran.map((option) => (
                            <MenuItem
                                key={option.id}
                                value={option.nama_pameran}
                            >
                                {option.nama_pameran}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <CSVLink data={body3} headers={header} filename={mynamefile}>
                    <Button
                        className={classes.btnexport}
                        variant="contained"
                        color="secondary"
                    >
                        Export All
                    </Button>
                </CSVLink>
            </Box>
            
            <Typography>
                List Data Pameran <b>{listPameran.toUpperCase()}</b>
                <br></br>
                Periode <b>{startpameran} s/d {endpameran}</b>
            </Typography>{" "}
            <br></br>
            <TableContainer style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={dataOverview}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
        </Box>
    );
}

export default PameranList;

if (document.getElementById("pameranlist")) {
    ReactDOM.render(<PameranList />, document.getElementById("pameranlist"));
}
