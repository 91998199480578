import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url("/images/login.jpg")',
    backgroundSize: 'cover',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

async function loginUser(credentials) {
  return fetch('https://api.aims.wijayatoyota.co.id/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }

export default function Login() {
  const classes = useStyles();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await loginUser({
      username,
      password
    });
    if ('access_token' in response) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
        localStorage.setItem('apiUrl', "https://api.aims.wijayatoyota.co.id/");
        localStorage.setItem('access_token', response['access_token']);
        localStorage.setItem('id_cabang', response['id_cabang']);
        localStorage.setItem('person', JSON.stringify(response['person']));
        localStorage.setItem('rules', JSON.stringify(response['rules']));
        if (response['rules'] === 'sales') {
          
          window.location.href = "/area/sales/list/datacustomer";

        } else if (response['rules'] === 'spv') {

          window.location.href = "/spvoverview/"+response['person'].toUpperCase();

        } else if (response['rules'] === 'sa') {

          window.location.href = "/area/sa";

        } else if (response['rules'] === 'skeeperho') {

          window.location.href = "/stock/overviewstock";

        } else if (response['rules'] === 'skeepercabang') {

          window.location.href = "/stock/overviewstock";

        } else if (response['rules'] === 'pdc') {

          window.location.href = "/stock/overviewstock";
      
        } else if (response['rules'] === 'crc') {
          
          window.location.href = "/area/crc/ultah";

        } else {
          
          window.location.href = "/dashboard";

        }
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image} />
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
        <Box
          component="img"
          sx={{
            height: 50,
            width: 150,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="Aims v3."
          src="/images/logoaims.png"
        />
          <Typography component="h5" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              name="username"
              label="Username"
              onChange={e => setUserName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Typography align="center">Copyright &copy; Wijaya Toyota. Version 3.0</Typography>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}