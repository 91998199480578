import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
  Default: "#81b71a",
  Blue: "#00B1E1",
  Cyan: "#37BC9B",
  Green: "#8CC152",
  Red: "#9e0000",
  Yellow: "#F6BB42",
};

const bulanlist = [
  {
    value: "1",
    label: "Januari",
  },
  {
    value: "2",
    label: "Februari",
  },
  {
    value: "3",
    label: "Maret",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "Mei",
  },
  {
    value: "6",
    label: "Juni",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "Agustus",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "Oktober",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "Desember",
  },
];

const tahunlist = [
  {
    value: "2018",
    label: "2018",
  },
  {
    value: "2019",
    label: "2019",
  },
  {
    value: "2020",
    label: "2020",
  },
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2023",
    label: "2023",
  },
];

const listPertanyaan = [
  {
    value: "Ya",
    label: "Ya",
  },
  {
    value: "Tidak",
    label: "Tidak",
  },
];

const listJeniscustomer = [
  {
    value: "NEW CUSTOMER - SUSPECT",
    label: "Suspect",
  },
  {
    value: "NEW CUSTOMER - PROSPEK",
    label: "Prospek",
  },
];

const listKategoriProspek = [
  {
    value: "BELUM PROSPEK",
    label: "BELUM PROSPEK",
  },
  {
    value: "LOW",
    label: "LOW",
  },
  {
    value: "MEDIUM",
    label: "MEDIUM",
  },
  {
    value: "HOT",
    label: "HOT",
  },
];

const listDemand = [
  {
    value: "First Buyer",
    label: "First Buyer",
  },
  {
    value: "Additional",
    label: "Additional",
  },
  {
    value: "Trade In",
    label: "Trade In",
  },
];

const useStyles = makeStyles((theme) => ({
  headerimage: {
    width: theme.spacing(10),
    height: theme.spacing(5),
  },
  thead: {
    backgroundColors: bgColors.Cyan,
  },
  btn: {
    marginTop: theme.spacing(2),
  },
  btnexport: {
    // marginLeft: theme.spacing(125),
    float: "right",
    marginBottom: theme.spacing(1),
    backgroundColors: bgColors.Green,
  },
  inputText: {
    width: "48%",
    marginLeft: theme.spacing(2),
  },
}));

function LprospeksaSales(props) {
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();
  const [bulan, setBulan] = React.useState(month+1);
  const [tahun, setTahun] = React.useState(year);
  const [nmCustomer, setCustomer] = React.useState("");
  const classes = useStyles();
  const [jeniscustomer, setJeniscustomer] = React.useState("");
  const [kategoriProspekList, setKategoriProspekState] = React.useState("");
  const [alamatCust, setalamatCust] = React.useState("");
  const [kecamatanCust, setkecamatanCust] = React.useState("");
  const [kelurahanCust, setkelurahanCust] = React.useState("");
  const [telpCust, settelpCust] = React.useState("");
  const [mobilDimilikiCust, setmobildimilikiCust] = React.useState("");
  const [demand, setDemand] = React.useState("");

  const handleChange = (event) => {
    setBulan(event.target.value);
  };
  const handleChange2 = (event) => {
    setTahun(event.target.value);
  };
  const handleCustomer = (event, value) => {
    const customername = value != null ? value.label : "";
    setCustomer(customername);
  };
  const handleJcust = (event) => {
    setJeniscustomer(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };
  const handleKtgProspek = (event) => {
    setKategoriProspekState(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };
  const handleDemand = (event) => {
    setDemand(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const isLoading = false;

  const [open, setOpen] = React.useState(false);
  const [openFu1, setOpenFU1] = React.useState(false);
  const [openFu2, setOpenFU2] = React.useState(false);
  const [openFu3, setOpenFU3] = React.useState(false);
  const [dtDo, setDtDo] = React.useState([]);

  const handleClickOpen = (eventValue) => {
    console.log(eventValue);
    setDtDo(eventValue);
    setalamatCust(eventValue.alamat);
    setInputs((values) => ({
      ...values,
      ["id"]: eventValue.id,
    }));
    setOpen(true);
  };

  const handleModalFU1 = (eventValue) => {
    setDtDo(eventValue);
    setalamatCust(eventValue.alamat);
    setkecamatanCust(eventValue.kecamatan);
    setkelurahanCust(eventValue.kelurahan);
    settelpCust(eventValue.telp);
    setmobildimilikiCust(eventValue.mobil_dimiliki);
    setInputs((values) => ({
      ...values,
      ["id"]: eventValue.id,
      ["customer"]: eventValue.customer,
      ["alamat"]: eventValue.alamat,
      ["kecamatan"]: eventValue.kecamatan,
      ["kelurahan"]: eventValue.kelurahan,
      ["telp"]: eventValue.telp,
      ["kendaraan_dimiliki"]: eventValue.mobil_dimiliki,
      ["demand_structure"]: "",
      ["sumber"]: "SERVICE ADVISOR",
    }));
    setOpenFU1(true);
  };

  const handleModalFU2 = (eventValue) => {
    setDtDo(eventValue);
    setalamatCust(eventValue.alamat);
    setkecamatanCust(eventValue.kecamatan);
    setkelurahanCust(eventValue.kelurahan);
    settelpCust(eventValue.telp);
    setmobildimilikiCust(eventValue.mobil_dimiliki);
    setInputs((values) => ({
      ...values,
      ["id"]: eventValue.id,
      ["customer"]: eventValue.customer,
      ["alamat"]: eventValue.alamat,
      ["kecamatan"]: eventValue.kecamatan,
      ["kelurahan"]: eventValue.kelurahan,
      ["telp"]: eventValue.telp,
      ["kendaraan_dimiliki"]: eventValue.mobil_dimiliki,
      ["demand_structure"]: "",
      ["sumber"]: "SERVICE ADVISOR",
    }));
    setOpenFU2(true);
  };

  const handleModalFU3 = (eventValue) => {
    setDtDo(eventValue);
    setalamatCust(eventValue.alamat);
    setkecamatanCust(eventValue.kecamatan);
    setkelurahanCust(eventValue.kelurahan);
    settelpCust(eventValue.telp);
    setmobildimilikiCust(eventValue.mobil_dimiliki);
    setInputs((values) => ({
      ...values,
      ["id"]: eventValue.id,
      ["customer"]: eventValue.customer,
      ["alamat"]: eventValue.alamat,
      ["kecamatan"]: eventValue.kecamatan,
      ["kelurahan"]: eventValue.kelurahan,
      ["telp"]: eventValue.telp,
      ["kendaraan_dimiliki"]: eventValue.mobil_dimiliki,
      ["demand_structure"]: "",
      ["sumber"]: "SERVICE ADVISOR",
    }));
    setOpenFU3(true);
  };

  const handleChangeInputAlamat = (event) => {
    setalamatCust(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInputKecamatan = (event) => {
    setkecamatanCust(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInputKelurahan = (event) => {
    setkelurahanCust(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInputTelepon = (event) => {
    settelpCust(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInputMobil = (event) => {
    setmobildimilikiCust(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClose = () => {
    setOpenFU1(false);
    setOpenFU2(false);
    setOpenFU3(false);
    setInputs([]);
  };

  // API List Data Prospek
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataProspek, setDataProspek] = useState([]);
  const [dataProspek2, setDataProspek2] = useState([]);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [refreshDt, setRefresh] = useState();

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getDataCustomer = async () => {
      const url = `https://api.aims.wijayatoyota.co.id/api/area/sales/prospek/list/customer`;
      try {
        const response = await axios.get(url);
        if (response.statusText === "") {
          setDataCustomer(response.data.data);
        } else {
          throw new Error("Request failed");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDataCustomer();
  }, []);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getData = async () => {
      const url = `https://api.aims.wijayatoyota.co.id/api/area/sales/prospek/showdata?customer=${nmCustomer}`;
      try {
        const response = await axios.get(url);
        if (response.statusText === "") {
          setDataProspek(response.data.data);
          setDataProspek2(response.data);
        } else {
          throw new Error("Request failed");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [nmCustomer, refreshDt]);

  const handleClick = (values) => {
    console.log(values);
  };

  const [inputs, setInputs] = useState([]);
  const handleChangeInput = (event) => {
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  //   Follow Up 1
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        "https://api.aims.wijayatoyota.co.id/api/area/sales/prospeksa/follupsatu",
        inputs
      )
      .then(function (response) {
        if (response.data.error == true) {
          swal("Error", "Data tidak boleh kosong!", "error", {
            buttons: false,
            timer: 2000,
          });
        } else {
          swal("Success", "Data Berhasil disimpan!", "success", {
            buttons: false,
            timer: 2000,
          });
          // window.location.reload();
          setOpenFU1(false);
          setRefresh(response);
        }
      });
  };
  //   Follow Up 2
  const handleSubmitFu2 = (event) => {
    event.preventDefault();
    axios
      .post(
        "https://api.aims.wijayatoyota.co.id/api/area/sales/prospeksa/follupdua",
        inputs
      )
      .then(function (response) {
        if (response.data.error == true) {
          swal("Error", "Data tidak boleh kosong!", "error", {
            buttons: false,
            timer: 2000,
          });
        } else {
          swal("Success", "Data Berhasil disimpan!", "success", {
            buttons: false,
            timer: 2000,
          });
          // window.location.reload();
          setOpenFU2(false);
          setRefresh(response);
        }
      });
  };
  //   Follow Up 1
  const handleSubmitFu3 = (event) => {
    event.preventDefault();
    axios
      .post(
        "https://api.aims.wijayatoyota.co.id/api/area/sales/prospeksa/folluptiga",
        inputs
      )
      .then(function (response) {
        if (response.data.error == true) {
          swal("Error", "Data tidak boleh kosong!", "error", {
            buttons: false,
            timer: 2000,
          });
        } else {
          swal("Success", "Data Berhasil disimpan!", "success", {
            buttons: false,
            timer: 2000,
          });
          // window.location.reload();
          setOpenFU3(false);
          setRefresh(response);
        }
      });
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <Toolbar />
      <Typography>
        <b>List Data Prospek SA</b>
      </Typography>{" "}
      <br />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={dataCustomer}
            onChange={handleCustomer}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer"
                helperText="Pilih Customer"
              />
            )}
          />
        </div>
      </Box>
      <br></br>
      <br></br>
      {nmCustomer != "" ? (
        <Box sx={{ flexGrow: 1 }}>
          {dataProspek.map((datval) => [
            <Card
              key={datval.id}
              sx={{
                width: "90%",
                margin: "0 auto",
                borderTop: "1px solid #e0e0e0",
                marginBottom: 2,
              }}
            >
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  <b>{datval.customer}</b>
                  <br></br>
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  SA {datval.sa}
                  <br></br>
                  {datval.tgl}
                  <br></br>
                  {datval.telp}
                  <br></br>
                  {datval.alamat}, {datval.kecamatan}, {datval.kelurahan}
                  <br></br>
                  <b>{datval.mobil_dimiliki}</b>
                  <br></br>
                  <b>{datval.no_rangka}</b>
                  <br></br>
                  KM <b>{datval.km}</b>
                  <br></br>
                  Keterangan <b>{datval.ket}</b>
                  <br></br>
                  Follow Up 1 <b>{datval.feedback}</b>
                  <br></br>
                  Follow Up 2 <b>{datval.follow_up_2}</b>
                  <br></br>
                  Follow Up 3 <b>{datval.follow_up_3}</b>
                  <br></br>
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={(event) => {
                    handleModalFU1(datval);
                  }}
                >
                  Follow Up 1
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={(event) => {
                    handleModalFU2(datval);
                  }}
                >
                  Follow Up 2
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={(event) => {
                    handleModalFU3(datval);
                  }}
                >
                  Follow Up 3
                </Button>
              </CardActions>
            </Card>,
          ])}
        </Box>
      ) : (
        ""
      )}
      {/* FOLLWUP 1 */}
      <Box style={{ width: "100%" }}>
        <Dialog
          open={openFu1}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth={fullWidth}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            style={{
              backgroundColor: bgColors.Yellow,
              marginBottom: "10px",
              color: "#ffffff",
            }}
          >
            {"Form Follow Up"}
          </DialogTitle>
          <DialogContent>
            <Typography>
              <Chip
                label={"Customer: " + dtDo.customer}
                color="primary"
                variant="outlined"
              />
              <br></br>
            </Typography>{" "}
            <br />
            <form>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  select
                  variant="outlined"
                  name="jenis_cust"
                  onChange={handleJcust}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Jenis Customer"
                >
                  {listJeniscustomer.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="sumber"
                  value="SERVICE ADVISOR"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  aria-readonly
                  helperText="Sumber"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="tanggal"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  type="date"
                  helperText="Tanggal"
                />
                <TextField
                  id="outlined-basic"
                  aria-aria-readonly
                  variant="outlined"
                  value={alamatCust}
                  name="alamat"
                  onChange={handleChangeInputAlamat}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Alamat"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  aria-aria-readonly
                  value={kecamatanCust}
                  variant="outlined"
                  name="kecamatan"
                  onChange={handleChangeInputKecamatan}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kecamatan"
                />
                <TextField
                  id="outlined-basic"
                  required
                  aria-aria-readonly
                  value={kelurahanCust}
                  variant="outlined"
                  name="kelurahan"
                  onChange={handleChangeInputKelurahan}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kelurahan"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  value={telpCust}
                  name="telp"
                  onChange={handleChangeInputTelepon}
                  sx={{ width: "50%" }}
                  size="small"
                  type="number"
                  helperText="No Telepon"
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="kendaraan_minati"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kendaraan Diminati"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="kendaraan_dimiliki"
                  value={mobilDimilikiCust}
                  onChange={handleChangeInputMobil}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kendaraan Dimiliki"
                />
                <TextField
                  id="outlined-basic"
                  select
                  onChange={handleKtgProspek}
                  label="Kategori"
                  helperText="Pilih Kategori"
                  name="kategoriprospek"
                  variant="outlined"
                  sx={{ width: "50%" }}
                  size="small"
                  required
                >
                  {listKategoriProspek.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  select
                  value={demand}
                  onChange={handleDemand}
                  label="Demand Structure"
                  helperText="Pilih Demand"
                  name="demand_structure"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  size="small"
                  style={{
                    display: kategoriProspekList != "HOT" ? "none" : "",
                  }}
                >
                  {listDemand.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="feedback"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Feedback"
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="keterangan"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Keterangan"
                />
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* FOLLWUP 2 */}
      <Box style={{ width: "100%" }}>
        <Dialog
          open={openFu2}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth={fullWidth}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            style={{
              backgroundColor: bgColors.Yellow,
              marginBottom: "10px",
              color: "#ffffff",
            }}
          >
            {"Form Follow Up"}
          </DialogTitle>
          <DialogContent>
            <Typography>
              <Chip
                label={"Customer: " + dtDo.customer}
                color="primary"
                variant="outlined"
              />
              <br></br>
            </Typography>{" "}
            <br />
            <form>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  select
                  variant="outlined"
                  name="jenis_cust"
                  onChange={handleJcust}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Jenis Customer"
                >
                  {listJeniscustomer.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="sumber"
                  value="SERVICE ADVISOR"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  aria-readonly
                  helperText="Sumber"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="tanggal"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  type="date"
                  helperText="Tanggal"
                />
                <TextField
                  id="outlined-basic"
                  aria-aria-readonly
                  variant="outlined"
                  value={alamatCust}
                  name="alamat"
                  onChange={handleChangeInputAlamat}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Alamat"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  aria-aria-readonly
                  value={kecamatanCust}
                  variant="outlined"
                  name="kecamatan"
                  onChange={handleChangeInputKecamatan}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kecamatan"
                />
                <TextField
                  id="outlined-basic"
                  required
                  aria-aria-readonly
                  value={kelurahanCust}
                  variant="outlined"
                  name="kelurahan"
                  onChange={handleChangeInputKelurahan}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kelurahan"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  value={telpCust}
                  name="telp"
                  onChange={handleChangeInputTelepon}
                  sx={{ width: "50%" }}
                  size="small"
                  type="number"
                  helperText="No Telepon"
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="kendaraan_minati"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kendaraan Diminati"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="kendaraan_dimiliki"
                  value={mobilDimilikiCust}
                  onChange={handleChangeInputMobil}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kendaraan Dimiliki"
                />
                <TextField
                  id="outlined-basic"
                  select
                  onChange={handleKtgProspek}
                  label="Kategori"
                  helperText="Pilih Kategori"
                  name="kategoriprospek"
                  variant="outlined"
                  sx={{ width: "50%" }}
                  size="small"
                  required
                >
                  {listKategoriProspek.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  select
                  value={demand}
                  onChange={handleDemand}
                  label="Demand Structure"
                  helperText="Pilih Demand"
                  name="demand_structure"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  size="small"
                  style={{
                    display: kategoriProspekList != "HOT" ? "none" : "",
                  }}
                >
                  {listDemand.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="feedback"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Feedback"
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="keterangan"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Keterangan"
                />
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSubmitFu2}
            >
              Submit
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* FOLLWUP 3 */}
      <Box style={{ width: "100%" }}>
        <Dialog
          open={openFu3}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth={fullWidth}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            style={{
              backgroundColor: bgColors.Yellow,
              marginBottom: "10px",
              color: "#ffffff",
            }}
          >
            {"Form Follow Up"}
          </DialogTitle>
          <DialogContent>
            <Typography>
              <Chip
                label={"Customer: " + dtDo.customer}
                color="primary"
                variant="outlined"
              />
              <br></br>
            </Typography>{" "}
            <br />
            <form>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  select
                  variant="outlined"
                  name="jenis_cust"
                  onChange={handleJcust}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Jenis Customer"
                >
                  {listJeniscustomer.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="sumber"
                  value="SERVICE ADVISOR"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  aria-readonly
                  helperText="Sumber"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="tanggal"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  type="date"
                  helperText="Tanggal"
                />
                <TextField
                  id="outlined-basic"
                  aria-aria-readonly
                  variant="outlined"
                  value={alamatCust}
                  name="alamat"
                  onChange={handleChangeInputAlamat}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Alamat"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  aria-aria-readonly
                  value={kecamatanCust}
                  variant="outlined"
                  name="kecamatan"
                  onChange={handleChangeInputKecamatan}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kecamatan"
                />
                <TextField
                  id="outlined-basic"
                  required
                  aria-aria-readonly
                  value={kelurahanCust}
                  variant="outlined"
                  name="kelurahan"
                  onChange={handleChangeInputKelurahan}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kelurahan"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  value={telpCust}
                  name="telp"
                  onChange={handleChangeInputTelepon}
                  sx={{ width: "50%" }}
                  size="small"
                  type="number"
                  helperText="No Telepon"
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="kendaraan_minati"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kendaraan Diminati"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="kendaraan_dimiliki"
                  value={mobilDimilikiCust}
                  onChange={handleChangeInputMobil}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Kendaraan Dimiliki"
                />
                <TextField
                  id="outlined-basic"
                  select
                  onChange={handleKtgProspek}
                  label="Kategori"
                  helperText="Pilih Kategori"
                  name="kategoriprospek"
                  variant="outlined"
                  sx={{ width: "50%" }}
                  size="small"
                  required
                >
                  {listKategoriProspek.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  select
                  value={demand}
                  onChange={handleDemand}
                  label="Demand Structure"
                  helperText="Pilih Demand"
                  name="demand_structure"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  size="small"
                  style={{
                    display: kategoriProspekList != "HOT" ? "none" : "",
                  }}
                >
                  {listDemand.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="feedback"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Feedback"
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="keterangan"
                  onChange={handleChangeInput}
                  sx={{ width: "50%" }}
                  size="small"
                  helperText="Keterangan"
                />
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSubmitFu3}
            >
              Submit
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default LprospeksaSales;

if (document.getElementById("listdataprospeksa")) {
  ReactDOM.render(
    <LprospeksaSales />,
    document.getElementById("listdataprospeksa")
  );
}
