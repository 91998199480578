import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import { OpenInFullRounded } from "@mui/icons-material";

const token = localStorage.getItem("access_token");
const person = JSON.parse(localStorage.getItem("person"));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    btndelete: {
        fontSize: theme.spacing(2),
    },
}));

function DataCustomer(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [openFu, setOpenFu] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const handleClose = () => setOpenFu(false);
    const [pageSize, setPageSize] = React.useState(25);
    const [fullWidth, setFullWidth] = React.useState(true);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const handleOpenFollUp = (event) => {
        setOpenFu(true);
        setInputs(values => ({...values, ["id"]: event.id}));
    }

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [refreshDt, setRefresh] = useState();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/area/sales/datacustomer?page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                    setLoading(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, refreshDt]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/summary/export/tdo?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                setAllProspek(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        // getAllData();
    }, [bulan, tahun]);

    const header = [
        "Tanggal",
        "Tanggal DO",
        "No SPK",
        "SPV",
        "Sales",
        "Customer",
        "Alamat",
        "Kecamatan",
        "Kelurahan",
        "Telp",
        "Pekerjaan",
        "Nama STNK",
        "Tipe",
        "Warna",
        "Kategori Sumber",
        "Sumber Prospek",
        "Leasing",
        "Hobby",
        "Keterangan",
        "CR 1",
        "CR 2",
        "Cek 1000",
        "Feedback",
    ];

    const body3 = allProspek.map((data) => [
        data.tgl,
        data.tgl_do,
        data.no_spk,
        data.nama_spv,
        data.nama_sales,
        data.nama_cust,
        data.alamat,
        data.kecamatan,
        data.kelurahan,
        data.telp,
        data.pekerjaan,
        data.nama_stnk,
        data.tipe,
        data.warna,
        data.kategori_sumber,
        data.sumber,
        data.leasing,
        data.hobby,
        data.ket,
        data.crsatu,
        data.crdua,
        data.cek1000,
        data.feedback,
    ]);

    const mynamefile = "List Bengkel_" + bulan + "-" + tahun + ".csv";

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        { field: "nama_cust", headerName: "Customer", minWidth: 250 },
        { field: "alamat", headerName: "Alamat", minWidth: 250 },
        { field: "kecamatan", headerName: "Kecamatan", minWidth: 250 },
        { field: "telp", headerName: "Telepon", minWidth: 250 },
        { field: "email", headerName: "Email", minWidth: 250 },
        { field: "ket", headerName: "Keterangan", minWidth: 250 },
        // { field: "last_activity", headerName: "Aktivitas Terakhir", minWidth: 250 },
        // { field: "activity_at", headerName: "Aktivitas Date", minWidth: 250 },
        {
            field: "Follow Up",
            renderCell: (cellEdit) => {
                return cellEdit.row.followup == null ? (
                    <Button
                        size="small"
                        variant="outlined"
                        color="inherit"
                        startIcon={<EditIcon />}
                        onClick={(event) => {
                            handleOpenFollUp(cellEdit.row);
                        } }
                    >
                        Follow Up
                    </Button>
                ) : (
                    <Typography>
                        {cellEdit.row.followup}
                    </Typography>
                );
            },
            minWidth: 200,
        },
    ];

    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios.post('https://api.aims.wijayatoyota.co.id/api/area/sales/followup', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                setRefresh(response);
                setOpenFu(false);
            }
        });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />

            <Alert>Halo, {person}! Berikut Database Customer.</Alert>

            <br></br>
            <TableContainer style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
            {/* Input Lokasi */}
            <Box style={{ width: "100%" }}>
        
                <form onSubmit={handleSubmit}>
                    <Dialog
                        open={openFu}
                        TransitionComponent={Transition}
                        keepMounted
                        maxWidth="md"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                        <DialogTitle
                        style={{
                            backgroundColor: bgColors.Yellow,
                            marginBottom: "10px",
                            color: "#ffffff",
                        }}
                        >
                        {"Follow Up"}
                        </DialogTitle>
                        <DialogContent>
                        <br></br>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="followup"
                                label="Follow Up"
                                onChange={handleChangeInput}
                                sx={{width: "100%"}}
                                size="small"
                                helperText="Follow Up"
                            />
                        </Box>
                        </DialogContent>
                        <DialogActions>
                        {loadButton === true ? 
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                        <Button onClick={handleClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>

            </Box>
        </Box>
    );
}

export default DataCustomer;
