import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import swal from "sweetalert";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const listPertanyaan = [
    {
        value: "Ya",
        label: "Ya",
    },
    {
        value: "Tidak",
        label: "Tidak",
    },
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    inputText: {
        width: "100%",
        marginLeft: theme.spacing(2),
    },
}));

function InputBooking(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [nmCustomer, setCustomer] = React.useState("");
    const [kecamatanOpt, setKecamatan] = React.useState([]);
    const classes = useStyles();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }, [nmCustomer]);

    const allOption = useEffect(() => {
        axios
            .get("https://api.aims.wijayatoyota.co.id/api/area/sales/select/option")
            .then((response) => {
                setKecamatan(response.data.kecamatan);
            });
    }, []);

    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const handleKecamatan = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .post("https://api.aims.wijayatoyota.co.id/api/booking/create", inputs)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setInputs([]);
                    window.location.href = "/area/sales/booking/listdata";
                }
            });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <br />
            <Box style={{ width: "100%" }}>
                <Card sx={{ padding: 2 }}>
                    <form onSubmit={handleSubmit}>
                        <CardContent>
                            <Typography>
                                <b>Input Booking</b>
                            </Typography>{" "}<br></br>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="customer"
                                    onChange={handleChangeInput}
                                    label="Customer"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="Nama Customer"
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="alamat"
                                    onChange={handleChangeInput}
                                    label="Alamat"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="Alamat"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    select
                                    name="kecamatan"
                                    onChange={handleKecamatan}
                                    label="Kecamatan"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="Pilih Kecamatan"
                                >
                                    {kecamatanOpt.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.nama_kecamatan}
                                        >
                                            {option.nama_kecamatan}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-basic"
                                    name="nohp"
                                    onChange={handleChangeInput}
                                    label="No HP"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    type="number"
                                    helperText="No HP"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="no_polisi"
                                    onChange={handleChangeInput}
                                    label="No Polisi"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="No Polisi"
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="no_rangka"
                                    onChange={handleChangeInput}
                                    label="No Rangka"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="No Rangka"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="kendaraan"
                                    onChange={handleChangeInput}
                                    label="Kendaraan"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="Kendaraan"
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="jenis_km"
                                    onChange={handleChangeInput}
                                    label="Jenis (KM)"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="Jenis (KM)"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="keluhan"
                                    onChange={handleChangeInput}
                                    label="Keluhan"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="Keluhan"
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="tgl_booking"
                                    onChange={handleChangeInput}
                                    label=""
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    type="date"
                                    helperText="Tanggal Booking"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="jam"
                                    onChange={handleChangeInput}
                                    label=""
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    type="time"
                                    helperText="Jam"
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="hobby"
                                    onChange={handleChangeInput}
                                    label="Hobby"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="Hobby / Aktivitas / Hal yang disenangi"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="makanan"
                                    onChange={handleChangeInput}
                                    label="Makanan Kesukaan"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="Makanan Kesukaan"
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="sosial_media"
                                    onChange={handleChangeInput}
                                    label="Akun Sosial Media"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                    helperText="Instagram / Facebook / Twitter"
                                />
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                            <Button size="small">Cancel</Button>
                        </CardActions>
                    </form>
                </Card>
            </Box>
        </Box>
    );
}

export default InputBooking;

if (document.getElementById("inputbooking")) {
    ReactDOM.render(<InputBooking />, document.getElementById("inputbooking"));
}
