import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const kategoriList = [
    {
        value: "UMUM",
        label: "Data Umum"
    }
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

function SpvListProspekSa(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [jenis, setJenis] = React.useState("UMUM");
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);

    const currUrl = window.location.href;
    const segment = currUrl.split("/");
    const spvname = segment[4];

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };
    const handleChange3 = (event) => {
        setJenis(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    // API List Data Prospek
    const [dataOverview, setDataOverview] = useState([]);
    const [dataOverview2, setDataOverview2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/spv/lsprospeksa/${spvname}?bulan=${bulan}&tahun=${tahun}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                
                if (response.statusText == "OK") {
                    setLoading(false);
                    setDataOverview(response.data.data);
                    setDataOverview2(response.data);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun, jenis]);

    const [rowCountState, setRowCountState] = React.useState(
        dataOverview2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataOverview2?.totalAll !== undefined
                ? dataOverview2?.totalAll
                : prevRowCountState
        );
    }, [dataOverview2?.totalAll, setRowCountState]);

    const columns = [
        { field: "sa", headerName: "SA", minWidth: 200 },
        { field: "tgl", headerName: "Tanggal", headerClassName: "headerbg" },
        { field: "sales", headerName: "Sales", minWidth: 200 },
        { field: "customer", headerName: "Customer", minWidth: 200 },
        { field: "alamat", headerName: "alamat", minWidth: 200 },
        { field: "kecamatan", headerName: "kecamatan", minWidth: 200 },
        { field: "kelurahan", headerName: "kelurahan", minWidth: 200 },
        { field: "telp", headerName: "telp", minWidth: 200 },
        { field: "kendaraan", headerName: "kendaraan", minWidth: 200 },
        { field: "ket", headerName: "ket", minWidth: 200 },
        { field: "feedback", headerName: "Follow UP 1", minWidth: 200 },
        { field: "follow_up_2", headerName: "Follow UP 2", minWidth: 200 },
        { field: "follow_up_3", headerName: "Follow UP 3", minWidth: 200 },
    ];

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/spv/export/lsprospeksa/${spvname}?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    setLoading(false);
                    setAllProspek(response.data.data);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getAllData();
    }, [bulan, tahun, jenis]);

    const header = [
        "SA",
        "Tanggal",
        "Sales",
        "Customer",
        "alamat",
        "kecamatan",
        "kelurahan",
        "telp",
        "kendaraan",
        "ket",
        "Follow UP 1",
        "Follow UP 2",
        "Follow UP 3",
    ];

    const body3 = allProspek.map((data) => [
        data.sa,
        data.tgl,
        data.sales,
        data.customer,
        data.alamat,
        data.kecamatan,
        data.kelurahan,
        data.telp,
        data.kendaraan,
        data.ket,
        data.feedback,
        data.follow_up_2,
        data.follow_up_3,
    ]);

    const mynamefile = "Data List Prospek_"+spvname+"-"+bulan+"-"+tahun+".csv";

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Data T DO",
            sheet: "T DO",
            tablePayload: {
                header,
                // accept two different data structures
                body: body3,
            },
        });
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography>
                <b>SPV Team {decodeURI(spvname)} : List Data Prospek SA</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >                    
                <CSVLink data={body3} headers={header} filename={mynamefile}>
                    <Button
                        className={classes.btnexport}
                        variant="contained"
                        color="secondary"
                    >
                        Export All
                    </Button>
                </CSVLink>
            </Box>
            <TableContainer style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={dataOverview}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
        </Box>
    );
}

export default SpvListProspekSa;

if (document.getElementById("spvlistprospeksa")) {
    ReactDOM.render(<SpvListProspekSa />, document.getElementById("spvlistprospeksa"));
}
