import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import swal from 'sweetalert';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import DeleteIcon from "@mui/icons-material/Delete";

// Modal Dialog
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Stack from '@mui/material/Stack';

import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "LEFT",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    btndelete: {
        fontSize: theme.spacing(2),
    },
}));

function InputData(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    const [pagekecamatan, setPagekecamatan] = React.useState(0);
    const [pagekecamatanSize, setPagekecamatanSize] = React.useState(25);
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };
    const handleClickOpenImport = (event) => {
        setOpen(true);
    };
    const handleClose = (event) => {
        setOpen(false);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const queryOptionsKecamatan = React.useMemo(
        () => ({
            pagekecamatan,
            pagekecamatanSize,
        }),
        [pagekecamatan, pagekecamatanSize]
    );

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [dataKecamatan, setDataKecamatan] = useState([]);
    const [dataKecamatan2, setDataKecamatan2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [refreshDt, setRefresh] = useState();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/area/mra/customerpasif/list/sandkecamatan?bulan=${bulan}&tahun=${tahun}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                setDataProspek(response.data.data);
                setDataProspek2(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun, refreshDt]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.all || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.all !== undefined
                ? dataProspek2?.all
                : prevRowCountState
        );
    }, [dataProspek2?.all, setRowCountState]);

    const columns = [
        { field: "nama_sa", headerName: "SA", minWidth: 250 },
    ];

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataKecamatan = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/area/mra/customerpasif/list/sandkecamatan?page=${pagekecamatan}&size=${pagekecamatanSize}`;
            try {
                const response = await axios.get(url);
                setDataKecamatan(response.data.dataKecamatan);
                setDataKecamatan2(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getDataKecamatan();
    }, [pagekecamatan, pagekecamatanSize,, refreshDt]);

    const [rowCountStateKecamatan, setRowCountStateKecamatan] = React.useState(
        dataKecamatan2?.allKecamatan || 0
    );

    React.useEffect(() => {
        setRowCountStateKecamatan((prevRowCountStateKecamatan) =>
            dataKecamatan2?.allKecamatan !== undefined
                ? dataKecamatan2?.allKecamatan
                : prevRowCountStateKecamatan
        );
    }, [dataKecamatan2?.allKecamatan, setRowCountStateKecamatan]);

    const columnsKecamatan = [
        { field: "nama_kecamatan", headerName: "Kecamatan", minWidth: 250 },
    ];
    

    const [fileUpload, setFileUp] = React.useState([]);
    const [inputsImport, setInputsImport] = React.useState([]);
    const [loadButton, setLoadButton] = React.useState(false);
    const hChangeInputFile = (event) => {
        setFileUp(event.target.files[0]);
        setInputsImport(values => ({...values, [event.target.name]: fileUpload}));
    }

    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        console.log(fileUpload);
        const formData = new FormData();
        
        formData.append('fileimport',fileUpload);
        axios.post('https://api.aims.wijayatoyota.co.id/api/area/mra/customerpasif/process/importharian', formData).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                setOpen(false);
                setRefresh();
                // window.location.href = "/stock/overviewstock";
            }
        });
        
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography>
                <b>Input Data</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
            {/* <CSVLink data={body3} headers={header} filename={mynamefile}>
                <Button
                    className={classes.btnexport}
                    variant="contained"
                    color="secondary"
                >
                    Export
                </Button>
            </CSVLink>&nbsp; */}
                <Stack spacing={1} direction="row">
                    <Button variant="outlined" size="small" style={{ border: "1px solid green", color: "green" }} href="https://api.aims.wijayatoyota.co.id/resources/template/template_import_harian_sa.xlsx">
                        Download Template Form
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={handleClickOpenImport}
                    >
                        Import
                    </Button>
                </Stack>
            </Box>
            <br></br>
            <Alert icon={<CheckIcon fontSize="inherit" />}  severity="info"><Typography>SA</Typography></Alert>
            <TableContainer style={{ height: 500, width: "100%", float: "left" }}>
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
            <br></br>
            <Alert style={{ marginTop: "10px" }} icon={<CheckIcon fontSize="inherit" />}  severity="info"><Typography>Kecamatan</Typography></Alert>
            <TableContainer style={{ height: 500, width: "100%", float: "left", marginBottom: "5px" }}>
                <DataGrid
                    rows={dataKecamatan}
                    rowCount={rowCountStateKecamatan}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={pagekecamatan}
                    pageSize={pagekecamatanSize}
                    paginationMode="server"
                    onPageChange={(newPagekecamatan) => setPagekecamatan(newPagekecamatan)}
                    onPageSizeChange={(newPagekecamatanSize) => setPagekecamatanSize(newPagekecamatanSize)}
                    columns={columnsKecamatan}
                />
            </TableContainer>

            {/* Form Import */}
            <Box style={{ width: "100%" }}>
        
                <form>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        // maxWidth="md"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                        <DialogTitle
                        style={{
                            backgroundColor: bgColors.Yellow,
                            marginBottom: "10px",
                            color: "#ffffff",
                        }}
                        >
                        {"Import Harian SA"}
                        </DialogTitle>
                        <DialogContent>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            <TextField
                                id="outlined-select-currency-native"
                                defaultValue=""
                                label=""
                                helperText="Pilih File"
                                onChange={hChangeInputFile}
                                sx={{width: "100%"}}
                                size= "small"
                                name="filestock"
                                type="file"
                            >
                            </TextField>
                        </Box>
                        </DialogContent>
                        <DialogActions>
                        {loadButton === true ? 
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                        <Button onClick={handleClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>
        </Box>
    );
}

export default InputData;