import React, { useEffect, useState } from "react";
import axios from "axios";
// import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import TableContainer from "@mui/material/TableContainer";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Chip from "@mui/material/Chip";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const person = JSON.parse(localStorage.getItem("person"));
const token = localStorage.getItem("access_token");
// const rules = JSON.parse(localStorage.getItem("rules"));

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

// const useStyles = makeStyles((theme) => ({
//     headerimage: {
//         width: theme.spacing(10),
//         height: theme.spacing(5),
//     },
//     thead: {
//         backgroundColors: bgColors.Cyan,
//     },
//     btn: {
//         marginTop: theme.spacing(2),
//     },
//     btnexport: {
//         float: "right",
//         marginBottom: theme.spacing(1),
//         backgroundColors: bgColors.Green,
//     },
//     btndelete: {
//         fontSize: theme.spacing(2),
//     },
// }));

function RequestStock(props) {

    // let currentDate = new Date();
    // let year = currentDate.getFullYear();
    // let month = currentDate.getMonth();
    // const [bulan, setBulan] = React.useState(month+1);
    // const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    // const classes = useStyles();
    const [fullWidth] = React.useState(true);
    const [inputs, setInputs] = useState([]);
    // const [inputsUpdate, setInputsUpdate] = useState([]);
    const [openApprove, setOpenApprove] = React.useState(false);
    const [openReject, setOpenReject] = React.useState(false);
    const handleClose = () => setOpenApprove(false);
    const handleCloseReject = () => setOpenReject(false);
    // const [TipeKendaraan, setTipeKendaraan] = useState("");
    // const [NoRangka, setNoRangka] = useState("");
    // const [Warna, setWarna] = useState("");
    const [refreshDt, setRefresh] = useState();

    const handleClickOpenApprove = (event) => {
        console.log(event);
        setOpenApprove(true);
        setInputs(values => ({
            ...values, 
            ["id_stock"]: event,
        }));
    }

    const handleClickOpenReject = (event) => {
        console.log(event);
        setOpenReject(true);
        setInputs(values => ({
            ...values, 
            ["id_stock"]: event,
        }));
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    //=====  KATEROGI ONHAND  =========/
    const [dataOnhand, setDataOnhand] = useState([]);
    const [dataOnhand2, setDataOnhand2] = useState([]);
    const [pageOnhand, setPageOnhand] = React.useState(0);
    const [pageSizeOnhand, setPageSizeOnhand] = React.useState(25);

    // const currUrl = window.location.href;
    // const segment = currUrl.split("/");
    // const spvname = segment[5];

    // const queryOptions = React.useMemo(
    //     () => ({
    //         pageOnhand,
    //         pageSizeOnhand,
    //     }),
    //     [pageOnhand, pageSizeOnhand]
    // );

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/stock/list/swapping?page=${pageOnhand}&size=${pageSizeOnhand}`;
            try {
                const response = await axios.get(url);
                setDataOnhand(response.data.data);
                setDataOnhand2(response.data);

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [pageOnhand, pageSizeOnhand, refreshDt]);

    const [rowCountStateOnhand, setRowCountStateOnhand] = React.useState(
        dataOnhand2?.allBarterOut || 0
    );
    
    React.useEffect(() => {
        setRowCountStateOnhand((prevRowCountStateOnhand) =>
            dataOnhand2?.allBarterOut !== undefined
                ? dataOnhand2?.allBarterOut
                : prevRowCountStateOnhand
        );
    }, [dataOnhand2?.allBarterOut, setRowCountStateOnhand]);

    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setInputs(values => ({
            ...values, 
            ["id_stock"]: event,
        }));
        // setLoadButton(true);
        axios.post('https://api.aims.wijayatoyota.co.id/api/stock/request/approve', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil diapprove!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                setRefresh(response);
                setOpenApprove(false);
            }
        });
    };

    const handleSubmitReject = (event) => {
        // setLoadButton(true); 
        axios.post('https://api.aims.wijayatoyota.co.id/api/stock/request/reject', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil direject!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                setRefresh(response);
                setOpenReject(false);
            }
        });
    };

    const columns = [
        { field: "model_in", headerName: "Model IN", minWidth: 300 },
        { field: "model_out", headerName: "Model OUT", minWidth: 300 },
        { field: "type_in", headerName: "Type IN", minWidth: 150 },
        { field: "type_out", headerName: "Type OUT", minWidth: 150 },
        { field: "no_rangka_in", headerName: "No Rangka IN", minWidth: 200 },
        { field: "no_rangka_out", headerName: "No Rangka OUT", minWidth: 200 },
        { field: "no_mesin_in", headerName: "No Mesin IN", minWidth: 200 },
        { field: "no_mesin_out", headerName: "No Mesin OUT", minWidth: 200 },
        { field: "warna_in", headerName: "Warna IN", minWidth: 200 },
        { field: "warna_out", headerName: "Warna OUT", minWidth: 200 },
        { field: "Status Swapping", renderCell: (cellValues) => {
            return cellValues.row.status == "approved" ? (
                <Chip
                    label={cellValues.row.status}
                    size= "small"
                    color= "success"
                />
            ) :  cellValues.row.status == "rejected" ? (
                <Chip
                    label={cellValues.row.status}
                    size= "small"
                    color= "error"
                />
            ) : (
                <Chip
                    label={cellValues.row.status}
                    size= "small"
                />
            );
        }, minWidth: 200, align: "center" },
        { field: "keterangan", headerName: "Keterangan", minWidth: 200 },
        { field: "created_by", headerName: "Dibuat Oleh", minWidth: 200 },
        { field: "Aksi", renderCell: (cellValues) => {
            return (
                <><Button
                    size="small"
                    variant="outlined"
                    color="success"
                    startIcon={<DoneIcon />}
                    onClick={(event) => {
                        handleClickOpenApprove(cellValues.row.id_stock);
                    } }
                >
                    Approve
                </Button>&nbsp;
                <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    startIcon={<CloseIcon />}
                    onClick={(event) => {
                        handleClickOpenReject(cellValues.row.id_stock);
                    } }
                >
                    Reject
                </Button></>
            )
        }, minWidth: 220, align: "center" },
    ];
    
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            
            <Alert>Halo, {person}! Berikut Data Request Stock.</Alert>

            {/* Kategori Onhand */}
            <br></br>
            <Box style={{}}>
                <TableContainer style={{ height: 500, width: "100%" }}>
                    <DataGrid
                        rows={dataOnhand}
                        rowCount={rowCountStateOnhand}
                        loading={isLoading}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        page={pageOnhand}
                        pageSize={pageSizeOnhand}
                        paginationMode="server"
                        onPageChange={(newPage) => setPageOnhand(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSizeOnhand(newPageSize)}
                        columns={columns}
                    />
                </TableContainer>
            </Box>
            {/* Reject Stock */}
            <Box style={{ width: "100%" }}>
        
                <form onSubmit={handleSubmitReject}>
                    <Dialog
                        open={openReject}
                        TransitionComponent={Transition}
                        keepMounted
                        maxWidth="md"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                        <DialogTitle
                        style={{
                            backgroundColor: bgColors.Yellow,
                            marginBottom: "10px",
                            color: "#ffffff",
                        }}
                        >
                        {"Reject"}
                        </DialogTitle>
                        <DialogContent>
                        <br></br>
                        <br></br>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="keterangan"
                                label="Keterangan Reject"
                                onChange={handleChangeInput}
                                sx={{width: "10%"}}
                                size="small"
                                helperText="Keterangan Reject"
                            />
                        </Box>
                        </DialogContent>
                        <DialogActions>
                        {loadButton === true ? 
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmitReject}
                                > 
                                    <Typography>Send</Typography>
                                </Button>
                            }
                        <Button onClick={handleCloseReject}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>

            </Box>

            {/* Approve Stock */}
            <Box style={{ width: "100%" }}>
        
                <form onSubmit={handleSubmit}>
                    <Dialog
                        open={openApprove}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                        <DialogTitle
                        style={{
                            backgroundColor: bgColors.Yellow,
                            marginBottom: "10px",
                            color: "#ffffff",
                        }}
                        >
                        {"Approve"}
                        </DialogTitle>
                        <DialogContent>
                        <br></br>
                        <br></br>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            <Typography>
                                <b>Apakah anda yakin akan approve stock ?</b>
                            </Typography>
                        </Box>
                        </DialogContent>
                        <DialogActions>
                        {loadButton === true ? 
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                > 
                                    <Typography>Oke</Typography>
                                </Button>
                            }
                        <Button onClick={handleClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>

            </Box>
        </Box>
            
        
    );
}

export default RequestStock;