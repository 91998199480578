import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Links from "@mui/material/Link";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

const token = localStorage.getItem("access_token");
const idCab = localStorage.getItem("id_cabang");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];
const jenislist = [
    {
        value: "kategori",
        label: "Per Kategori Sumber",
    },
    {
        value: "sumber",
        label: "Per Sumber",
    },
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: "right",
        marginBottom: theme.spacing(1),
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Yellow,
        color: theme.palette.common.white,
    },
}));
const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Red,
        color: theme.palette.common.white,
    },
}));
const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Green,
        color: theme.palette.common.white,
    },
}));
const StyledTableCell4 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Cyan,
        color: theme.palette.common.white,
    },
}));
const StyledTableCell5 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Blue,
        color: theme.palette.common.white,
    },
}));

function SCorongProspek(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    let jenisdefault = "kategori";
    const [bulan, setBulan] = React.useState(month + 1);
    const [tahun, setTahun] = React.useState(year);
    const [jenis, setJenis] = React.useState(jenisdefault);
    const classes = useStyles();

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };
    const handleChange3 = (event) => {
        setJenis(event.target.value);
    };

    const isLoading = false;

    // API List Data Prospek
    const [dataPeriode, setPeriode] = useState([]);
    const [dataPeriodeSuspect, setPeriodeSuspect] = useState([]);
    const [dataPeriodeProspek, setPeriodeProspek] = useState([]);
    const [dataPeriodeHot, setPeriodeHot] = useState([]);
    const [dataPeriodeSpk, setPeriodeSpk] = useState([]);
    const [dataPeriodeDo, setPeriodeDo] = useState([]);
    const [dataKategori, setDataKategori] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [loadingSet, setLoadingData] = useState(false);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            setLoadingData(true);
            const url = `https://api.aims.wijayatoyota.co.id/api/summary/corong?bulan=${bulan}&tahun=${tahun}&kategori=${jenis}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setPeriode(response.data.periode);
                    setPeriodeSuspect(response.data.dt_periode.suspect);
                    setPeriodeProspek(response.data.dt_periode.prospek);
                    setPeriodeHot(response.data.dt_periode.hot);
                    setPeriodeSpk(response.data.dt_periode.spk);
                    setPeriodeDo(response.data.dt_periode.do);
                    setDataKategori(response.data.dt_kategori);
                    setLoadingData(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [bulan, tahun, jenis]);

    console.log(Object.values(dataKategori));
    // console.log(Object.entries(dataKategori));

    const header = [
        "Jenis",
        "Tanggal",
        "SPV",
        "Sales",
        "Customer",
        "Alamat",
        "Kelurahan",
        "Telp",
        "Minat",
        "Sumber Prospek",
        "Kategori",
        "Planning To Buy",
        "Test Drive",
        "SPK",
    ];

    const body3 = allProspek.map((data) => [
        data.jenis_cust,
        data.tgl,
        data.nama_spv,
        data.nama_sales,
        data.nama_cust,
        data.alamat,
        data.kelurahan,
        data.telp,
        data.kendaraan_minat,
        data.sumber,
        data.kategori,
        data.planning_to_buy,
        data.test_drive,
        data.spk,
    ]);

    const mynamefile = "Data Prospek_" + bulan + "-" + tahun + ".csv";

    const urlExport = `https://api.aims.wijayatoyota.co.id/api/export?bulan=${bulan}&tahun=${tahun}&kategori=${jenis}&id_cabang=${idCab}`;

    //const rows = {dataProspek};
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography>
                <b>Summary : List Data Prospek</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Jenis"
                        value={jenis}
                        onChange={handleChange3}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Jenis"
                    >
                        {jenislist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </div>
                <Links href={urlExport} underline="none">
                    <Button className={classes.btnexport} size="small" variant="contained" color="secondary" startIcon={<FileDownloadIcon />}>
                        Export Corong
                    </Button>
                </Links>
            </Box>
            <br></br>
            <Box style={{ width: "100%" }}>
                {loadingSet === true ? 
                    // <center><CircularProgress size="1.2rem" color="inherit" /></center>
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress />
                    </Stack>
                :  
                    ""
                }
            </Box>
            <b>Corong Prospek {dataPeriode}</b>
            <Table sx={{ maxWidth: 300 }}>
                <TableBody>
                    <TableRow>
                        <TableCell>Suspect</TableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                            {dataPeriodeSuspect.total}
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Prospek</TableCell>
                        <TableCell></TableCell>
                        <StyledTableCell2></StyledTableCell2>
                        <StyledTableCell2></StyledTableCell2>
                        <StyledTableCell2></StyledTableCell2>
                        <StyledTableCell2 style={{ textAlign: "center" }}>
                            {dataPeriodeProspek.total}
                        </StyledTableCell2>
                        <StyledTableCell2></StyledTableCell2>
                        <StyledTableCell2></StyledTableCell2>
                        <StyledTableCell2></StyledTableCell2>
                        <TableCell></TableCell>
                        <TableCell>{dataPeriodeProspek.persen}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Hot</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <StyledTableCell3></StyledTableCell3>
                        <StyledTableCell3></StyledTableCell3>
                        <StyledTableCell3 style={{ textAlign: "center" }}>
                            {dataPeriodeHot.total}
                        </StyledTableCell3>
                        <StyledTableCell3></StyledTableCell3>
                        <StyledTableCell3></StyledTableCell3>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{dataPeriodeHot.persen}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>SPK</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <StyledTableCell4></StyledTableCell4>
                        <StyledTableCell4 style={{ textAlign: "center" }}>
                            {dataPeriodeSpk.total}
                        </StyledTableCell4>
                        <StyledTableCell4></StyledTableCell4>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{dataPeriodeSpk.persen}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>DO</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <StyledTableCell5 style={{ textAlign: "center" }}>
                            {dataPeriodeDo.total}
                        </StyledTableCell5>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{dataPeriodeDo.persen}%</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <br></br>
            {Object.values(dataKategori).map((item, idx) => (
                <div key={item.kategori}>
                    <b>Corong Prospek {item.kategori}</b>
                    <Table sx={{ maxWidth: 300 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Suspect</TableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell
                                    style={{ textAlign: "center" }}
                                >
                                    {item.suspect.total}
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Prospek</TableCell>
                                <TableCell></TableCell>
                                <StyledTableCell2></StyledTableCell2>
                                <StyledTableCell2></StyledTableCell2>
                                <StyledTableCell2></StyledTableCell2>
                                <StyledTableCell2
                                    style={{ textAlign: "center" }}
                                >
                                    {item.prospek.total}
                                </StyledTableCell2>
                                <StyledTableCell2></StyledTableCell2>
                                <StyledTableCell2></StyledTableCell2>
                                <StyledTableCell2></StyledTableCell2>
                                <TableCell></TableCell>
                                <TableCell>
                                    {item.prospek.persen}%
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Hot</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <StyledTableCell3></StyledTableCell3>
                                <StyledTableCell3></StyledTableCell3>
                                <StyledTableCell3
                                    style={{ textAlign: "center" }}
                                >
                                    {item.hot.total}
                                </StyledTableCell3>
                                <StyledTableCell3></StyledTableCell3>
                                <StyledTableCell3></StyledTableCell3>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{item.hot.persen}%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>SPK</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <StyledTableCell4></StyledTableCell4>
                                <StyledTableCell4
                                    style={{ textAlign: "center" }}
                                >
                                    {item.spk.total}
                                </StyledTableCell4>
                                <StyledTableCell4></StyledTableCell4>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{item.spk.persen}%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>DO</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <StyledTableCell5
                                    style={{ textAlign: "center" }}
                                >
                                    {item.do.total}
                                </StyledTableCell5>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{item.do.persen}%</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <br></br>
                    <br></br>
                </div>
            ))}
        </Box>
    );
}

export default SCorongProspek;

if (document.getElementById("scorongprospek")) {
    ReactDOM.render(
        <SCorongProspek />,
        document.getElementById("scorongprospek")
    );
}
