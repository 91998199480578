import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { CSVLink } from "react-csv";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [
    {
        value: "2018",
        label: "2018",
    },
    {
        value: "2019",
        label: "2019",
    },
    {
        value: "2020",
        label: "2020",
    },
    {
        value: "2021",
        label: "2021",
    },
    {
        value: "2022",
        label: "2022",
    },
    {
        value: "2023",
        label: "2023",
    },
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

function ListDataHariansa(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const [listSales, setSalesState] = React.useState();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    // API List Data Prospek
    const [dataOverview, setDataOverview] = useState([]);
    const [dataOverview2, setDataOverview2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [optSales, setSales] = useState([]);
    const [loadingSet, setLoadingData] = useState(false);

    const handleChangeSales = (event) => {
        setSalesState(event.target.value);
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/sa/data/list`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    // response.data.data instanceof Object;
                    setSales(response.data.data);
                    
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            setLoadingData(true);
            const url = `https://api.aims.wijayatoyota.co.id/api/area/mra/customerpasif/list/data?nama_sa=${listSales}&bulan=${bulan}&tahun=${tahun}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataOverview(response.data.data);
                    setDataOverview2(response.data);
                    setLoading(false);
                    setLoadingData(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun, listSales]);

    const [rowCountState, setRowCountState] = React.useState(
        dataOverview2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataOverview2?.totalAll !== undefined
                ? dataOverview2?.totalAll
                : prevRowCountState
        );
    }, [dataOverview2?.totalAll, setRowCountState]);

    const columns = [
        { field: "sumber", headerName: "Sumber", minWidth: 100 },
        { field: "tgl", headerName: "Tanggal", minWidth: 100 },
        { field: "waktu", headerName: "Waktu", minWidth: 100 },
        { field: "nama_sa", headerName: "SA", minWidth: 150 },
        { field: "nama_cust", headerName: "Customer", minWidth: 250},
        { field: "telp", headerName: "Telp", minWidth: 150 },
        { field: "kecamatan", headerName: "Kecamatan", minWidth: 200 },
        { field: "no_pol", headerName: "No Polisi", minWidth: 200 },
        { field: "tipe", headerName: "Tipe", minWidth: 200 },
        { field: "tgl_fu", headerName: "Tanggal FU", minWidth: 200 },
        { field: "bisa_dihubungi", headerName: "Bisa dihubungi", minWidth: 200 },
        { field: "kpn_service", headerName: "Kapan Service", minWidth: 200 },
        { field: "alasan", headerName: "Alasan", minWidth: 200 },
        { field: "ket", headerName: "Keterangan", minWidth: 200 },
        {
            field: "Action",
            renderCell: (cellValues) => {
                return (
                    <Button
                        className={classes.btndelete}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={(event) => {
                            // handleClick(cellValues);
                        }}
                    >
                        Delete
                    </Button>
                );
            },
            minWidth: 200,
        },
    ];

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api.aims.wijayatoyota.co.id/api/area/mra/export/hariansa?nama_sa=${listSales}&bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setAllProspek(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getAllData();
    }, [bulan, tahun, listSales]);

    const header = [
        "Sumber",
        "Tanggal",
        "Waktu",
        "SA",
        "Customer",
        "Telp",
        "Kecamatan",
        "No Polisi",
        "Tipe",
        "Tanggal FU",
        "Bisa dihubungi",
        "Kapan Service",
        "Alasan",
        "Keterangan",
    ];

    const body3 = allProspek.map((data) => [
        data.sumber,
        data.tgl,
        data.waktu,
        data.nama_sa,
        data.nama_cust,
        data.telp,
        data.kecamatan,
        data.no_pol,
        data.tipe,
        data.tgl_fu,
        data.bisa_dihubungi,
        data.kpn_service,
        data.alasan,
        data.ket,
    ]);

    const mynamefile = listSales+" Data Harian SA_"+bulan+"-"+tahun+".csv";

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography>
                <b>List Data</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        defaultValue=''
                        label="Pilih SA"
                        onChange={handleChangeSales}
                        helperText="Pilih SA"
                    >
                        {optSales.map((option) => (
                            <MenuItem key={option.id} value={option.nama_sa}>
                                {option.nama_sa}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </div>
                <CSVLink data={body3} headers={header} filename={mynamefile}>
                    <Button
                        className={classes.btnexport}
                        variant="contained"
                        color="secondary"
                    >
                        Export All
                    </Button>
                </CSVLink>
            </Box>
            <br></br>
            <Box style={{ width: "100%" }}>
                {loadingSet === true ? 
                    // <center><CircularProgress size="1.2rem" color="inherit" /></center>
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress />
                    </Stack>
                :  
                    ""
                }
            </Box>
            <TableContainer style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={dataOverview}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
        </Box>
    );
}

export default ListDataHariansa;
